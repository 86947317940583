export default [
  {
    head: '宣传栏目',
    links: [
      {
        title: '公司概括',
        href: '/infomation?doc=introduce'
      }
      // {
      //   title: '公司新闻',
      //   href: ''
      // },
      // {
      //   title: '人员招聘',
      //   href: ''
      // }
    ]
  },
  {
    head: '旅客须知',
    links: [
      {
        title: '运输总条件',
        href: '/infomation?doc=transport'
      },
      {
        title: '网上购物须知',
        href: '/infomation?doc=online'
      },
      {
        title: '行李须知',
        href: '/infomation?doc=baggage'
      },
      {
        title: '客票退改规定',
        href: '/infomation?doc=refund'
      }
    ]
  },
  {
    head: '网站支持',
    links: [
      // {
      //   title: '常见问题',
      //   href: ''
      // },
      // {
      //   title: '旅客投诉',
      //   href: ''
      // },
      {
        title: '联系我们',
        href: '/infomation?doc=contact'
      }
    ]
  }
]
