import { SliderCaptcha } from 'ace-ui-eking'
import { message } from 'ant-design-vue'
import Captcha from './App'
import './index.less'

Captcha.install = (Vue) => {
  Vue.use(SliderCaptcha)
  Vue.component(Captcha.name, Captcha)
  Vue.prototype.$message = message
}

export default Captcha
