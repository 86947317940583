import { mapGetters } from 'vuex'

export default {
  name: 'j-flight-city-selector',
  props: {
    dataSource: Array,
    value: String
  },
  computed: {
    ...mapGetters([
      'flightCities'
    ])
  },
  render (h) {
    const { dataSource, flightCities, value } = this
    const options = {
      props: {
        dataSource: dataSource || flightCities || [],
        ...this.$attrs
      }
    }

    return <air-flight-city-selector { ...options } value={ value } onChange={ this.onChange }></air-flight-city-selector>
  },
  methods: {
    onChange (value) {
      this.$emit('change', value)
    }
  }
}
