import moment from 'moment'

export function format (value, format = 'YYYY-MM-DD') {
  if (!value) {
    return '-'
  }

  try {
    return moment(value).format(format)
  } catch (err) {
    console.error('moment format filter error: ', err)
    return '-'
  }
}
