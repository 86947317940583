export default [
  {
    name: 'orderManager',
    title: '我的订单',
    visible: true,
    children: [
      {
        name: 'orderSearch',
        title: '订单查询',
        visible: true,
        path: '/orderManager/orderSearch'
      },
      {
        name: 'refundSearch',
        title: '退票单查询',
        visible: true,
        path: '/orderManager/refundSearch'
      },
      {
        name: 'depositPaySearch',
        title: '押金支付查询',
        visible: true,
        path: '/orderManager/depositPaySearch'
      },
      {
        name: 'depositRefundSearch',
        title: '押金申退查询',
        visible: true,
        path: '/orderManager/depositRefundSearch'
      },
      {
        name: 'differRefundSearch',
        title: '差错退款单查询',
        visible: true,
        path: '/orderManager/differRefundSearch'
      },
      {
        name: 'depositBalanceSearch',
        title: '押金冲抵',
        visible: true,
        path: '/orderManager/depositBalanceSearch'
      },
      {
        name: 'insuranceSearch',
        title: '保险查询',
        visible: true,
        path: '/orderManager/insuranceSearch'
      }
    ]
  },
  {
    name: 'userManager',
    title: '账号管理',
    visible: true,
    children: [
      {
        name: 'userInfo',
        title: '我的资料',
        visible: true,
        path: '/userManager/userInfo'
      },
      {
        name: 'passwordChange',
        title: '修改密码',
        visible: true,
        path: '/userManager/passwordChange'
      },
      {
        name: 'subAccount',
        title: '管理子账号',
        visible: true,
        path: '/userManager/subAccount'
      }
    ]
  },
  {
    name: 'businessNotices',
    title: '业务通告',
    visible: true,
    children: [
      {
        name: 'notice',
        title: '通知公告',
        visible: true,
        path: '/noticeManager/notice'
      },
      {
        name: 'advice',
        title: '投诉建议',
        visible: true,
        path: '/noticeManager/advice'
      },
      {
        name: 'advicePhoneSearch',
        title: '处理电话查询',
        visible: true,
        path: '/noticeManager/advicePhoneSearch'
      }
    ]
  }
]
