<template>
    <div class="app-wrap joy-app-wrap">
        <Navbar />
        <app-main />
    </div>
</template>

<script>
import AppMain from './AppMain'
import Navbar from './components/Navbar'

export default {
  name: 'LayoutHome',
  components: {
    AppMain,
    Navbar
  }
}
</script>
