import { axios } from '@/utils/axios'

// 获取滑块验证码
export async function fetchCaptcha () {
  try {
    const data = await axios({
      url: '/api/user/auth/captcha/slider',
      method: 'get'
    })

    return {
      bg: data.backgroundImage,
      block: data.sliderImage,
      state: data.requestId
    }
  } catch (err) {
    return Promise.reject(err)
  }
}

// 校验滑块验证码
export async function validateCaptcha (params) {
  try {
    const data = await axios({
      url: '/api/user/auth/captcha/validate',
      method: 'post',
      data: params
    })

    return data
  } catch (err) {
    return Promise.reject(err)
  }
}
