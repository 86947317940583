// options to filters
export function parseToFilters (list) {
  return list.map(item => {
    return {
      text: item.label,
      value: item.value
    }
  })
}

// options to map
export function parseToMap (list) {
  const map = {}

  list.forEach(item => {
    map[item.value] = item.label
  })

  return map
}
