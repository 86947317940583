
import Vue from 'vue'

const state = {
  token: '',
  userData: ''
}

const getters = {
  token: state => state.token,
  userData: state => state.userData
}

const mutations = {
  SET_TOKEN (state, newValue) {
    state.token = newValue
  },
  SET_Login_Userinfo (state) {
    const userInfo = Vue.ls.get('Login_Userinfo')

    state.userData = userInfo
  }
}
const actions = {
  // logout
  logout ({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      // commit('SET_Login_Userinfo', {})
      Vue.ls.remove('Access-Token')
      Vue.ls.remove('Login_Userinfo')
      resolve()
    })
  },
  // remove token
  resetToken ({ commit, dispatch }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      // commit('SET_Login_Userinfo', {})
      Vue.ls.remove('Access-Token')
      Vue.ls.remove('Login_Userinfo')
      resolve()
    })
  },
  async setToken ({ commit }, token) {
    await Vue.ls.set('Access-Token', token, 7 * 24 * 60 * 60 * 1000)
    commit('SET_TOKEN', token)
  },
  async setUserData ({ commit }, userData) {
    const userInfo = { username: userData.username, realname: userData.nickname }
    await Vue.ls.set('Login_Userinfo', {
      ...userData,
      ...userInfo
    }, 7 * 24 * 60 * 60 * 1000)
    commit('SET_Login_Userinfo')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
