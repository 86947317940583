// 局部引入全局组件
import Vue from 'vue'
import {
  Menu,
  Row,
  Col,
  Button,
  Input,
  InputNumber,
  Checkbox,
  Select,
  Radio,
  Form,
  Table,
  Icon,
  AutoComplete,
  Spin,
  Popover,
  DatePicker,
  ConfigProvider,
  Calendar,
  Upload,
  Modal,
  Steps,
  Tabs,
  Cascader,
  Descriptions,
  Alert,
  Switch,
  Tooltip,
  message
} from 'ant-design-vue'

import handleError from '@/utils/handleError'

Vue.use(Menu)
Vue.use(Row)
Vue.use(Col)
Vue.use(Button)
Vue.use(Input)
Vue.use(Cascader)
Vue.use(InputNumber)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Radio)
Vue.use(Form)
Vue.use(DatePicker)
Vue.use(ConfigProvider)
Vue.use(Calendar)
Vue.use(Upload)
Vue.use(Table)
Vue.use(Icon)
Vue.use(AutoComplete)
Vue.use(Popover)
Vue.use(Spin)
Vue.use(Descriptions)
Vue.use(Alert)
Vue.use(Modal)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Steps)
Vue.use(Tabs)
Vue.use(message)

const messageError = message.error

Vue.prototype.$message = message

// 重写error
Vue.prototype.$message.error = function () {
  handleError(arguments, messageError)
}

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$success = Modal.success
Vue.prototype.$modalError = Modal.error
Vue.prototype.$info = Modal.info
