export default [
  {
    title: '首页',
    name: 'home',
    path: '/'
  },
  {
    title: '团队预订',
    name: 'book',
    path: '/book'
  },
  {
    title: '订单查询',
    name: 'orderManager',
    path: '/orderManager'
  },
  {
    title: '业务通告',
    name: 'notice',
    path: '/noticeManager'
  }
]
