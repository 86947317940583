import { Modal } from 'ant-design-vue'

class SingleModal {
  constructor () {
    this._instance = null
  }

  error (opt) {
    if (!this._instance) {
      this._instance = Modal.error(opt)
      return this._instance
    } else {
      this._instance.update(opt)
    }
  }
}

export default SingleModal
