<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>审批流转过程</h4>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="status" slot-scope="text">
          {{ text | status }}
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import { status } from '@/filters/flow'
import { format } from '@/filters/time'

export default {
  name: 'admin-flow-table',
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: '环节',
          dataIndex: 'activitiName'
        },
        {
          title: '处理人',
          dataIndex: 'assignee'
        },
        {
          title: '处理意见',
          dataIndex: 'auditRemark'
        },
        {
          title: '处理时间',
          dataIndex: 'auditTime'
        },
        {
          title: '状态',
          dataIndex: 'auditStatus',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '后续操作',
          dataIndex: 'nextActivitiName'
        }
      ]
    }
  },
  props: {
    list: Array
  },
  filters: {
    status,
    format
  }
}
</script>
