<template>
  <a-popover>
        <template slot="content">
        <div class="popover-user">
            <template v-if="!userData.username">
              <div><router-link to="/login">登录</router-link></div>
            </template>
            <template v-else>
              <div><router-link to="/userManager/userInfo">我的信息</router-link></div>
              <div v-if="userData.type === 0" >
                <a href="/admin/home">切换到后台</a>
              </div>
              <div @click="logoutHandle">退出登录</div>
            </template>
        </div>
        </template>
        <a-button class="user-info-btn button-radius" type="primary">{{ userData.username || '未登录' }}</a-button>
    </a-popover>
</template>

<script>
import { userLogout } from '@/api/user'

export default {
  name: 'UserPop',
  computed: {
    userData () {
      return this.$store.state.user.userData || {}
    }
  },
  data () {
    return {

    }
  },
  methods: {
    async logoutHandle () {
      try {
        await userLogout()
        await this.$store.dispatch('user/logout')
        this.$router.push({ path: '/login' })
      } catch (e) {
        // 登出请求失败一样清空重新登录
        await this.$store.dispatch('user/logout')
        this.$router.push({ path: '/login' })
        this.$message.error(e.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>

.popover-user {
  cursor: pointer;
  line-height: 2em;
  a {
    color: #333
  }
}
</style>
