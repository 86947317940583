import { find } from 'lodash'
import store from '@/store'

export function getCityInfo (code, key) {
  if (!code) {
    return ''
  }

  const cities = store.state.app.flightCities
  const city = find(cities, { code })

  return city ? city[key] : code
}
