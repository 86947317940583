import Vue from 'vue'
import { parseToFilters, parseToMap } from './util'

// 订单状态
export const status = [
  {
    value: "",
    label: '全部'
  },
  {
    value: "AS",
    label: '申请成功'
  },
  {
    value: "WK",
    label: '等待确认'
  },
  {
    value: "WW",
    label: '订单未完成'
  },
  {
    value: "WP",
    label: '等待支付'
  },
  {
    value: "PI",
    label: '支付中'
  },
  {
    value: "HP",
    label: '支付未出票'
  },
  {
    value: "PF",
    label: '已出票'
  },
  {
    value: "XX",
    label: '取消订单'
  },
  {
    value: "RI",
    label: '退票申请中'
  },
  {
    value: "RF",
    label: '已退订'
  },
  {
    value: "CF",
    label: "改期"
  },
  {
    value: "EX",
    label: '换开'
  },
  {
    value: "SO",
    label: '改签'
  },
  {
    value: "WF",
    label: '待同步乘客'
  }
]

export const statusFilters = parseToFilters(status)

export const statusMap = parseToMap(status)

const airlineSourceMap = {
  GS: 'CGS',
  FU: 'CFU',
  '8L': 'C8L',
  GT: 'CGT',
  Y8: 'CY8',
  JD: 'CJD'
}

// 订单来源
const sourceList = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '套票渠道',
    value: 'CTP'
  },
  {
    label: '移动端H5',
    value: 'CH5'
  },
  {
    label: 'APP',
    value: 'CAP'
  },
  {
    label: '移动端小程序',
    value: 'CMG'
  },
  {
    label: '携程',
    value: 'CXC'
  },
  {
    label: '同程',
    value: 'CTC'
  },
  {
    label: '飞猪',
    value: 'CTB'
  },
  {
    label: '套票小程序',
    value: 'CGW'
  },
  {
    label: '航班管家',
    value: 'CHG'
  },
  {
    label: '途牛',
    value: 'CTU'
  },
  {
    label: '美团',
    value: 'CMT'
  },
  {
    label: '去哪儿',
    value: 'CQN'
  },
  {
    label: '京东',
    value: 'CJDO'
  },
  {
    label: '航旅纵横',
    value: 'CHZ'
  },
  {
    label: '周游科技',
    value: 'CZY'
  }
]

let ready = false

export const source = function () {
  if (!ready) {
    const airline = Vue.ls.get('airline')

    if (airline) {
      const value = airlineSourceMap[airline]

      if (value) {
        sourceList.splice(1, 0, {
          label: '官网订单',
          value
        })
        ready = true
      }
    }
  }

  return sourceList
}

let sourceArray

export const sourceFilters = function () {
  source()

  if (!sourceArray) {
    sourceArray = parseToFilters(sourceList)
  }

  return sourceArray
}

let sourceSet

export const sourceMap = function () {
  source()

  if (!sourceSet) {
    sourceSet = parseToMap(sourceList)
  }

  return sourceSet
}

export const fixStatus = [
  {
    label: '全部',
    value: ''
  },
  {
    value: 'ERROR',
    label: '未处理'
  },
  {
    value: 'HANDLED',
    label: '已处理'
  }
]

export const fixStatusFilters = parseToFilters(fixStatus)

export const userType = [
  {
    label: '全部',
    value: ''
  },
  {
    label: 'B2B',
    value: 'AGENT'
  },
  {
    label: 'B2C',
    value: 'USER'
  },
  {
    label: '航空公司',
    value: 'CARRIER'
  }
]

export const userTypeFilters = parseToFilters(userType)

// 承运人
export const airlineCode = [
  {
    value: '8L',
    label: '祥鹏航空'
  },
  {
    value: 'FU',
    label: '福州航空'
  },
  {
    value: 'GS',
    label: '天津航空'
  },
  {
    value: 'HU',
    label: '海南航空'
  }
]

export const airlineCodeMap = parseToMap(airlineCode)

// 客票状态
export const ticketStatusMap = {
  'OPEN_FOR_USE': '正常',
  'OPEN FOR USE': '正常',
  'VOID': '已作废',
  'REFUNDED': '已退票',
  'CHECKED_IN': '已值机',
  'CHECKED IN': '已值机',
  'LIFT_OR_BOARED': '已登机',
  'LIFT OR BOARED': '已登机',
  'USED_OR_FLOWN': '已使用',
  'USED OR FLOWN': '已使用',
  'SUSPENDED': '暂停使用',
  'PRINT_OR_EXCH': '已换开',
  'PRINT OR EXCH': '已换开',
  'EXCHANGE': '已换开',
  'FIM_OR_EXCHANGE': '已换开',
  'FIM OR EXCHANGE': '已换开',
  'NN': '未出票',
  'XX': '已取消',
  'EXCHANGED': '已改升'
}

// 航班状态
export const segmentStatusMap = {
  'RR': '已订座并确认完成出票',
  'HK': '已订座',
  'HL': '已订座并确认完成出票',
  'HN': '候补',
  'NO': '航空公司不允许销售',
  'UC': '不接受候补或订座',
  'UN': '航班取消',
  'XL': '取消候补',
  'SA': '信息航段，不占座',
  'XX': '取消确认或申请'
}

export const issCodeMap = {
  GS: 826,
  FU: 666,
  '8L': 859,
}
