import { FlightCitySelector } from 'ace-ui-eking'
import JFlightCitySelector from './index.jsx'

/* istanbul ignore next */
JFlightCitySelector.install = function (Vue) {
  Vue.use(FlightCitySelector)
  Vue.component(JFlightCitySelector.name, JFlightCitySelector)
}

export default JFlightCitySelector
