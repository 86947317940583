import Vue from 'vue'
import moment from 'moment'

import { airCity } from './flight'

Vue.filter('day', (date, format = 'YYYY-MM-DD') => {
    return moment(date).format(format)
})

Vue.filter('dateTime', (date) => {
    return moment(date).format('HH:mm')
})

Vue.filter('airCity', airCity)

Vue.filter('amount', (amount)=>{
    return Math.floor(+amount * 100) / 100
})

Vue.prototype.$getAirCity = airCity