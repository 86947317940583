import Vue from 'vue'

const getters = {
  flightCities: state => {
    state.app.flightCities = Vue.ls.get('FLIGHT_CITY')
    return state.app.flightCities
  }
}

export default getters
