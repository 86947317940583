import { parseToMap } from './util'

// 流转状态
export const status = [
  {
    label: '已处理',
    value: 'AUDIT_FINISHED'
  },
  {
    label: '处理中',
    value: 'AUDITING'
  }
]
export const statusMap = parseToMap(status)
