<template>
    <div class="app-wrap joy-app-wrap">
        <Navbar />
        <div class="order-layout">
          <div class="order-layout-side">
            <SideNav />
          </div>
          <div class="order-layout-main">
            <app-main />
          </div>
        </div>
    </div>
</template>

<script>
import AppMain from './AppMain'
import Navbar from '../LayoutPrimary/components/Navbar' // 引用LayoutPrimary的navbar
import SideNav from './components/SideNav' // 引用LayoutPrimary的navbar

import '@/style/order-common.less'

export default {
  name: 'LayoutHome',
  components: {
    AppMain,
    Navbar,
    SideNav
  },
  mounted () {
    document.getElementsByTagName('body')[0].style.background = '#f8f8f8' // 手动设置该layout时，body颜色
  },
  destroyed () {
    document.getElementsByTagName('body')[0].style.background = ''
  }
}
</script>

<style lang="less" scoped>
body {
  background: #f8f8f8;
}

.order-layout {
  position: relative;
}

.order-layout-side {
  width: 164px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 30px;
  background: #f8f8f8;
}

.order-layout-main {
  margin-left: 164px;
  padding-top: 34px;
}
</style>
