import { axios } from '@/utils/axios'

// import '@/mock'

// 用户登录
export async function userLogin (data) {
  try {
    const result = await axios({
      url: '/api/user/auth/login',
      method: 'post',
      data: data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}
// 用户登出
export async function userLogout () {
  try {
    const result = await axios({
      url: '/api/user/auth/logout',
      method: 'get'
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}
// 用户详情
export async function userDetail () {
  try {
    const result = await axios({
      url: '/api/user/agent/detail',
      method: 'get'
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 用户修改
export async function userUpdate (data) {
  try {
    const result = await axios({
      url: '/api/user/agent/update',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 用户注册
export async function userRegister (data) {
  try {
    const result = await axios({
      url: '/api/audit/process/AGENT_REGISTER_AUDIT',
      method: 'post',
      data: data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改密码
export async function changePassword (data) {
  try {
    const result = await axios({
      url: '/api/user/agent/account/pwd/update',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 子账号查询
export async function fetchSubAccount (data) {
  try {
    const result = await axios({
      url: '/api/user/agent/sub/account/page',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 修改子账号状态
export async function changeStatus (data) {
  try {
    const result = await axios({
      url: `/api/user/agent/sub/account/${data.status === 0 ? 'open' : 'close'}/${data.id}`,
      method: 'get'
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 添加子账号
export async function addSubAccount (data) {
  try {
    const result = await axios({
      url: '/api/user/agent/sub/account/register',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取地区部门信息
export async function fetchDeptList () {
  try {
    const result = await axios({
      url: '/api/user/dept/region/select',
      method: 'get'
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}
