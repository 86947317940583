<template>
  <div class="nav-bar-wrap">
    <div class="nav-bar-main">
      <div class="nav-bar-logo"></div>
      <div class="nav-list-wrap">
        <div class="nav-list">
          <div
            class="nav-item"
            :class="{ active: curRoute === item.path }"
            v-for="item in navList"
            :key="item.name"
          >
            <router-link :to="item.path"
              ><div class="nav-item-title">{{ item.title }}</div></router-link
            >
          </div>
        </div>
        <div class="nav-line"></div>
      </div>
      <div class="nav-user-info">
        <UserPop/>
      </div>
    </div>
    <div class="top-notice">
      <TopNoticeBar/>
    </div>
  </div>
</template>

<script>
import TopNoticeBar from '../TopNoticeBar'
import UserPop from '@/components/UserPop'
import navData from '@/common/nav-data'

export default {
  name: 'Navbar',
  components: {
    TopNoticeBar,
    UserPop
  },
  computed: {
    curRoute () {
      return this.$route.path
    }
  },
  data: function () {
    return {
      navList: navData
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.nav-bar-wrap {
  height: @joy-home-nav-height;
  background: rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 100;
}

.nav-bar-main {
  position: relative;
  height: 60px;
}

.nav-bar-logo {
  width: 145px;
  height: 36px;
  position: relative;
  left: 52px;
  top: 12px;
  background: url("~@/assets/images/nav-logo.png");
}

.nav-user-info {
  position: absolute;
  right: 52px;
  top: 12px;

  .user-info-btn {
    min-width: 88px;
  }
}

.nav-list-wrap {
  position: absolute;
  top: 12px;
  right: 268px;
}

.nav-list {
  width: 584px;
  padding-bottom: 20px;
  font-size: 16px;
  // border-bottom: 1px solid #fff;

  .nav-item {
    display: table-cell;
    padding-right: 114px;
    &:last-of-type {
      padding-right: 0;
    }

    &.active {
      .nav-item-title {
        color: @joy-orange-color;
        font-weight: bold;
        position: relative;

        &:after {
          content: "";
          display: block;
          height: 2px;
          width: 100%;
          background: @joy-orange-color;
          position: absolute;
          bottom: -21px;
        }
      }
    }
  }
  .nav-item-title {
    color: #fff;
  }
}

.top-notice {
  margin-top: 12px;
}
</style>
