
import { axios } from '@/utils/axios'

// import '@/mock'

// 航班查询
export async function queryFlight (data) {
  try {
    const result = await axios({
      url: '/api/shopping/query',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}
// 航班预订
export async function sumbitOrder (data) {
  try {
    const result = await axios({
      url: '/api/audit/process/ORDER_AUDIT',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 航点数据
export async function getFlightCities () {
  try {
    const data = await axios({
      url: '/api/segment/airport/list',
      method: 'post',
      data: {}
    })

    return data.map(item => {
      return {
        city: item.chiName,
        name: item.airportName,
        code: item.airportCode,
        pinyin: item.pinyin,
        english: '',
        letter: item.firstLetter,
        other: item.isInternal === 'ABRORD'
      }
    })
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取限额规则
export async function getLimit (data) {
  try {
    const result = await axios({
      url: '/api/limitAmount/match',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}
