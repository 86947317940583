import Vue from 'vue'
import route from '.'
import store from '../store'

route.beforeEach(async (to, from, next) => {
  window.document.title = to.meta.title === undefined ? '航空' : to.meta.title
  // 不需要登录的白名单
  const whiteList = ['/register']

  const token = Vue.ls.get('Access-Token')
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    let noNeedLogin = false

    whiteList.forEach((module) => {
      if (new RegExp(module).test(to.path)) {
        noNeedLogin = true
      }
    })

    if (to.path === '/login') {
      next()
    } else {
      if (!store.state.user.token) {
        if (noNeedLogin) {
          next()
        } else {
          next(`/login?redirect=${to.path}`)
        }
      } else {
        next()
      }
    }
  }
})
