import Vue from 'vue'
import axios from 'axios'
// import { Modal } from 'ant-design-vue'
import SingleModal from '@/components/SingleModal'
// import FingerprintJS from '@fingerprintjs/fingerprintjs'

import router from '@/router'
import store from '@/store'

const singleModal = new SingleModal()

const service = axios.create({
  timeout: 60000
})
// const fp = Vue.ls.get('Fingerprint')

// if (!fp) {
// (async () => {
// We recommend to call `load` at application startup.
// const finger = await FingerprintJS.load()

// The FingerprintJS agent is ready.
// Get a visitor identifier when you'd like to.
// const result = await finger.get()

// This is the visitor identifier:
// fp = result.visitorId
// Vue.ls.set('Fingerprint', fp)
// })()
// }

// request interceptor
service.interceptors.request.use(request => {
  const token = Vue.ls.get('Access-Token')

  if (token) {
    request.headers.token = token
  }

  // if (fp) {
  // request.headers.browser = fp
  // }

  return request
}, err => {
  return Promise.reject(err)
})

service.interceptors.response.use(response => {
  const { status, data: result } = response
  // const token = Vue.ls.get('Access-Token')

  // if (headers && headers.token && token !== headers.token) {
  //   Vue.ls.set('Access-Token', headers.token, 2 * 60 * 60 * 1000)
  // }

  if (status === 200) {
    const { code, data, message } = result

    if (code === 0) {
      return data
    } else {
      if (code === 100020007 || code === 100019999) {
        singleModal.error({
          content: message || '请先登录！',
          onOk: () => {
            store.dispatch('user/logout').then(() => {
              router.push({ path: '/login', query: { redirect: router.app.$route.fullPath } })
            })
          }
        })

        // 返回Promise让业务逻辑卡住，因为axios默认会return，导致resolve
        return new Promise(() => {})
      } else {
        const err = new Error(message || '网络异常，请稍后再试！')
        err.code = code

        if (data) {
          err.data = data
        }

        return Promise.reject(err)
      }
    }
  }
}, err => {
  const { status } = err.response || {}

  if (status === 401) {
    singleModal.error({
      content: '登录超时，请重新登录。',
      onOk: () => {
        store.dispatch('user/logout').then(() => {
          router.push({ path: '/login', query: { redirect: router.app.$route.fullPath } })
        })
      }
    })
    return Promise.reject(new Error('401'))
  }

  if (status === 403) {
    return Promise.reject(new Error('无权限'))
  }

  return Promise.reject(err)
})

export default {
  vm: {},
  install (Vue) {
    Vue.prototype.$axios = service
  }
}

export {
  service as axios
}
