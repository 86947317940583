<template>
  <div class="nav-bar-wrap">
    <div class="nav-bar-main">
      <router-link :to="{path: '/'}">
        <div class="nav-bar-logo"></div>
      </router-link>
      <div class="nav-list-wrap">
        <div class="nav-list">
          <div
            class="nav-item"
            :class="{ active: curRoute === item.path }"
            v-for="item in navList"
            :key="item.name"
          >
            <router-link :to="item.path"
              ><div class="nav-item-title">{{ item.title }}</div></router-link
            >
          </div>
        </div>
        <div class="nav-line"></div>
      </div>
      <div class="nav-user-info">
        <UserPop/>
      </div>
    </div>
  </div>
</template>

<script>
import navData from '@/common/nav-data'
import UserPop from '@/components/UserPop'

export default {
  name: 'Navbar',
  components: {
    UserPop
  },
  computed: {
    curRoute () {
      return this.$route.path
    }
  },
  data: function () {
    return {
      navList: navData
    }
  }
}
</script>

<style lang="less" scoped>
.nav-bar-wrap {
  background: @joy-darkblue-color;
  position: relative;
  z-index: 100;
}

.nav-bar-main {
  position: relative;
  height: 60px;
}

.nav-bar-logo {
  width: 145px;
  height: 36px;
  position: relative;
  left: 52px;
  top: 12px;
  background: url("~@/assets/images/nav-logo.png");
}

.nav-user-info {
  position: absolute;
  right: 52px;
  top: 12px;

  .user-info-btn {
    min-width: 88px;
  }
}

.nav-list-wrap {
    position: absolute;
    right: 246px;
    top: 18px;
}

.nav-list {
  width: 584px;
  font-size: 16px;

  .nav-item {
    display: table-cell;
    padding-right: 114px;

    &:last-of-type {
      padding-right: 0;
    }

    &.active {
      .nav-item-title {
        font-weight: bold;
        color: #fff;
        position: relative;
      }
    }
  }
  .nav-item-title {
    color: #ccc;

    &:hover {
      color: #fff;
    }
  }
}
</style>
