
import { axios } from '@/utils/axios'
import { groupBy } from 'lodash'
import moment from 'moment'

// 获取公告列表
export async function fetchList (data) {
  try {
    const result = await axios({
      url: '/api/notice/page',
      method: 'post',
      data: {
        status: 'ACTIVE',
        // scope: 'AGENT',
        deployTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        size: 10,
        ...data
      }
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取公告明细
export async function fetchDetail (id) {
  try {
    const result = await axios({
      url: `/api/notice/detail/${id}`,
      method: 'get'
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 获取处理电话查询
export async function fetchLineContact (data) {
  try {
    const result = await axios({
      url: '/api/lineContact/page',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 投诉建议
export async function advise (data) {
  try {
    const result = await axios({
      url: '/api/suggest/addBatch',
      method: 'post',
      data
    })

    return result
  } catch (err) {
    return Promise.reject(err)
  }
}

// 推荐航线
export async function recommend () {
  try {
    const result = await axios({
      url: '/api/auction/notice/list',
      method: 'post',
      data: {
        page: 0,
        size: 50,
        status: 'ACTIVE'
      }
    })
    result.forEach(item => {
      const splitLine = item.line.split('/')
      const orgDst = splitLine[0].split('-')
      item.depCode = orgDst[0]
      item.arrCode = orgDst[1]
      item.tripType = 'ST'

      if (splitLine.length > 1) {
        if (splitLine[0].split('-')[0] === splitLine[1].split('-')[1]) {
          item.tripType = 'RT'
        } else {
          item.tripType = 'MW'
        }
      } else {
        item.tripType = 'ST'
      }
    })
    const groups = groupBy(result, 'depCode')
    const list = [
      {
        title: '竞拍航线',
        list: result.slice(0, 10)
      }
    ]

    for (const key in groups) {
      list.push({
        title: key,
        list: groups[key]
      })
    }

    return list.slice(0, 5)
  } catch (err) {
    return Promise.reject(err)
  }
}
