import { airlineCodeMap, ticketStatusMap, segmentStatusMap, statusMap, sourceMap } from '@/dicts/flight'
import { getCityInfo } from '@/utils/util'
import { mapFilter } from './util'

export function status (value) {
  return mapFilter(value, statusMap)
}

export function airlineCode (value) {
  return mapFilter(value, airlineCodeMap)
}

export function ticketStatus (value) {
  return mapFilter(value, ticketStatusMap)
}

export function segmentStatus (value) {
  return mapFilter(value, segmentStatusMap)
}

export function airCity(value, transition = false, key = 'city') {
  if (!value) {
    return '?'
  }

  if (!transition){
    return value
  }

  return getCityInfo(value, key) || '?'
}

export function source (value) {
  return mapFilter(value, sourceMap())
}
