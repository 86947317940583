import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import Storage from 'vue-ls'
import App from './App.vue'
import router from './router'
import store from './store'

import './vendor'
import './style/common.less'
import './router/router-guard' // 路由守卫
import './config/settings'
import './filters/common'

import AdminFlowTable from '@/components/flow-table'

Vue.config.productionTip = false

Vue.use(AdminFlowTable)
Vue.use(Storage, {
  namespace: 'joy_b2b_', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local' // storage name session, local, memory
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
