<template>
    <div class="home-footer">
        <div class="link-warp clearfix">
            <div class="footer-link-module"
                v-for="(chunk, index) in linkChunks"
                :key="index"
            >
                <div class="link-module-head">{{ chunk.head }}</div>
                <div class="link-list">
                    <a class="link-item"
                    v-for="(link, index) in chunk.links"
                    :key="index"
                    :href="link.href"
                    target="_blank"
                    >
                    {{ link.title }}</a>
                </div>
            </div>
            <div class="footer-link-module">
                <div class="link-module-head">客户服务及投诉电话</div>
                <div class="footer-phone">400 868 0000</div>
                <div class="footer-qrcode">
                  <img :src="qrcodeImg" alt="">
                </div>
            </div>

        </div>
        <div class="footer-license">热线：400 868 0000|©JoyAir航空有限责任公司版权所有 陕ICP备09025016</div>
    </div>
</template>

<script>
import linkData from '@/common/link-data'

const LINK_CHUNK = linkData
export default {
  name: 'Footer',
  data: function () {
    return {
      linkChunks: LINK_CHUNK,
      qrcodeImg: require('@/assets/images/qrcode.png')
    }
  }
}
</script>

<style lang="less" scoped>
.home-footer {
    padding: 26px 40px 0;
    background: url("~@/assets/images/footer-bg.png") no-repeat center;
    background-size: cover;
}
.footer-link-module {
    width: 192px;
    float: left;
    & +.footer-link-module {
        margin-left: 80px;
    }
}
.link-module-head {
    font-size: 16px;
    font-weight: bold;
    line-height: 31px;
    color: @joy-darkblue-color;
    padding-bottom: 10px;
    border-bottom: 1px solid #dddddd;
}
.link-list {
    padding-top: 12px;
    padding-bottom: 20px;
}
.link-item {
    display: block;
    font-size: 12px;
    color: #666;

    & + .link-item {
        margin-top: 12px;
    }
}

.footer-phone {
  color: @joy-orange-color;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}
.footer-license {
    padding: 20px 0;
    border-top: 1px solid #ddd;
    color: #999;
    font-size: 16px;
    text-align: center;
}
</style>
