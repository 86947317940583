<template>
    <div class="top-notice-bar">
      <a-icon class="notice-icon" type="sound"></a-icon>
      <swiper class="notice-swiper" ref="noticeSwiper" :options="swiperOptions">
        <swiper-slide v-for="notice in notices" :key="notice.id">
          <a :href="`/noticeManager/notice/${notice.id}`">
            <span>{{ notice.title }}</span>
            <span class="notice-date">{{ notice.createTime }}</span>
          </a>
        </swiper-slide>
      </swiper>
      <div class="notice-more">
        <a href="/noticeManager/notice">查看更多</a>
      </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchList } from '@/api/notice'

export default {
  name: 'TopNoticeBar',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        direction: 'vertical',
        height: 20,
        loop: true
      },
      notices: []
    }
  },
  computed: {
    swiper () {
      return this.$refs.noticeSwiper && this.$refs.noticeSwiper.$swiper
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        const data = await fetchList({
          page: 0
        })

        this.notices = data.content || []
        this.$nextTick(() => {
          this.swiper && this.swiper.slideTo(3, 1000, false)
        })
      } catch (err) {
        this.$message.error(err.message)
      }
    }
  }
}
</script>

<style lang="less">
.top-notice-bar {
  margin: 0 60px;
  overflow: hidden;

  a {
    display: block;
    color: #fff;
  }

  .notice-date {
    margin-left: 30px;
  }
}

.notice-icon {
  width: 20px;
  margin-right: 10px;
  color: @joy-orange-color;
  display: inline-block;
  vertical-align: middle;
}

.notice-more {
  display: inline-block;
  vertical-align: middle;
  float: right;

  a {
    color: #fff;
  }
}

.notice-swiper {
  height: 20px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}
</style>
