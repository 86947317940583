<template>
  <div class="side-nav">
    <a-menu
      :default-selected-keys="selectedKeys"
      :default-open-keys="openKeys"
      mode="inline"
    >
      <template v-for="nav in navList">
        <a-sub-menu
          :key="nav.name"
          v-if="nav.visible"
        >
          <span slot="title"><span class="title-align">{{nav.title}}</span></span>
          <template v-for="child in nav.children">
            <a-menu-item :key="child.name" v-if="child.visible">
              <router-link :to="child.path">{{ child.title }}</router-link>
            </a-menu-item>
          </template>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>

<script>
import SIDE_MENU from '@/common/order-side-menu'

export default {
  name: 'SideNav',
  watch: {
    '$route' (route) {
      this.selectedKeys = [route.name]
    }
  },
  computed: {
    userData () {
      return this.$store.state.user.userData || {}
    }
  },
  created () {
    this.selectedKeys = [this.$route.name]
    this.initNav()
  },
  data () {
    return {
      navList: SIDE_MENU,
      selectedKeys: [],
      openKeys: [
        'orderManager',
        'userManager',
        'businessNotices'
      ]
    }
  },
  methods: {
    initNav () {
      this.navList.forEach((nav) => {
        if (nav.children.length) {
          nav.children.forEach((chd) => {
            if (chd.name === 'subAccount') {
              chd.visible = this.userData.type !== 2 // 子代理人不显示管理子账号菜单
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less">
.side-nav {
  .ant-menu {
    background: #f8f8f8;
    border-right: 0;

    .ant-menu-submenu-title {
      font-size: 14px;
      font-weight: bold;

      .title-align {
        padding-left: 24px;
      }
    }

    .ant-menu-sub {
      .ant-menu-item {
        font-size: 12px;
        height: 20px;
        line-height: 20px;

        &.ant-menu-item-selected {
          background: #f8f8f8;
          font-weight: bold;
          &::after {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
