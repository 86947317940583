<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { mapActions, mapMutations } from 'vuex'

export default {
  data () {
    return {
      locale: zhCN
    }
  },
  created () {
    const token = this.$ls.get('Access-Token')
    if (token) {
      this.fetchFlightCities()
    }

    this.SET_Login_Userinfo()
  },
  methods: {
    ...mapMutations({
      SET_Login_Userinfo: 'user/SET_Login_Userinfo'
    }),
    ...mapActions([
      'fetchFlightCities'
    ])
  }
}
</script>

<style lang="less">

</style>
