<template>
    <div class="app-wrap">
        <Navbar />
        <app-main />
        <Footer/>
    </div>
</template>

<script>
import AppMain from './AppMain'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default {
  name: 'LayoutHome',
  components: {
    AppMain,
    Navbar,
    Footer
  }
}
</script>
