import Vue from 'vue'
import VueRouter from 'vue-router'

import LayoutHome from '../layout/LayoutHome' // 首页layout
import LayoutPrimary from '../layout/LayoutPrimary' // 普通layout
import LayoutOrder from '../layout/LayoutOrder' // 订单页layout

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LayoutHome,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: { title: '首页' }
      }
    ]
  },
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login'),
    meta: { title: '登录' }
  },
  {
    path: '/book',
    component: LayoutPrimary,
    children: [
      {
        path: '',
        name: 'Book',
        component: () => import('@/views/book'),
        meta: { title: '团队预订' }
      },
      {
        path: '/book/success',
        name: 'BookSuccess',
        component: () => import('@/views/bookSuccess'),
        meta: { title: '预订成功' }
      }
    ]
  },
  {
    path: '/register',
    component: LayoutPrimary,
    children: [
      {
        path: '',
        name: 'Register',
        component: () => import('@/views/register'),
        meta: { title: '代理人注册' }
      }
    ]
  },
  {
    path: '/insurance',
    component: LayoutPrimary,
    children: [
      {
        path: 'book',
        name: 'insuranceBook',
        component: () => import('@/views/insurance/insurance-book'),
        meta: { title: '订单保险预订' }
      }
    ]
  },
  {
    path: '/orderManager',
    component: LayoutOrder,
    children: [
      {
        path: '/',
        redirect: { path: '/orderManager/orderSearch' }
      },
      {
        path: '/orderManager/orderSearch',
        name: 'orderSearch',
        component: () => import('@/views/orderManager/orderSearch'),
        meta: { title: '我的订单' }
      },
      {
        path: '/orderManager/orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/orderManager/detail'),
        meta: { title: '订单详情' }
      },
      {
        path: '/orderManager/orderRefund',
        name: 'orderRefund',
        component: () => import('@/views/orderManager/refund'),
        meta: { title: '订单退票' }
      },
      {
        path: '/orderManager/refundSearch',
        name: 'refundSearch',
        component: () => import('@/views/orderManager/refundSearch'),
        meta: { title: '退票单查询' }
      },
      {
        path: '/orderManager/refundDetail',
        name: 'refundDetail',
        component: () => import('@/views/orderManager/refund-detail'),
        meta: { title: '退票订详情' }
      },
      {
        path: '/orderManager/depositPaySearch',
        name: 'depositPaySearch',
        component: () => import('@/views/orderManager/depositPaySearch'),
        meta: { title: '押金支付查询' }
      },
      {
        path: '/orderManager/depositRefundSearch',
        name: 'depositRefundSearch',
        component: () => import('@/views/orderManager/depositRefundSearch'),
        meta: { title: '押金退款查询' }
      },
      {
        path: '/orderManager/depositRefundDetail',
        name: 'depositRefundDetail',
        component: () => import('@/views/orderManager/refund-deposit-detail'),
        meta: { title: '押金退款详情' }
      },
      {
        path: '/orderManager/differRefundSearch',
        name: 'differRefundSearch',
        component: () => import('@/views/orderManager/differRefundSearch'),
        meta: { title: '差错退款单查询' }
      },
      {
        path: '/orderManager/differRefundApply',
        name: 'differRefundApply',
        component: () => import('@/views/orderManager/differRefundApply'),
        meta: { title: '差错退款申请' }
      },
      {
        path: '/orderManager/differRefundDetail',
        name: 'differRefundDetail',
        component: () => import('@/views/orderManager/differ-refund-detail'),
        meta: { title: '差错单退款详情' }
      },
      {
        path: '/orderManager/depositBalanceSearch',
        name: 'depositBalanceSearch',
        component: () => import('@/views/orderManager/depositBalanceSearch'),
        meta: { title: '押金冲抵' }
      },
      {
        path: '/orderManager/insuranceSearch',
        name: 'insuranceSearch',
        component: () => import('@/views/orderManager/insuranceSearch'),
        meta: { title: '保险查询' }
      },
      {
        path: '/orderManager/insuranceDetail',
        name: 'insuranceDetail',
        component: () => import('@/views/orderManager/insurance-detail'),
        meta: { title: '保险订单详情' }
      }
    ]
  },
  {
    path: '/userManager',
    component: LayoutOrder,
    children: [
      {
        path: '/',
        redirect: { path: '/userManager/userInfo' }
      },
      {
        path: '/userManager/userInfo',
        name: 'userInfo',
        component: () => import('@/views/userManager/userInfo'),
        meta: { title: '我的资料' }
      },
      {
        path: '/userManager/passwordChange',
        name: 'passwordChange',
        component: () => import('@/views/userManager/passwordChange'),
        meta: { title: '修改密码' }
      },
      {
        path: '/userManager/subAccount',
        name: 'subAccount',
        component: () => import('@/views/userManager/subAccount'),
        meta: { title: '管理子账号' }
      }
    ]
  },
  {
    path: '/noticeManager',
    component: LayoutOrder,
    children: [
      {
        path: '/',
        redirect: { path: '/noticeManager/notice' }
      },
      {
        path: '/noticeManager/notice',
        name: 'notice',
        component: () => import('@/views/noticeManager/notice'),
        meta: { title: '通知公告' }
      },
      {
        path: '/noticeManager/notice/:id',
        name: 'notice-detail',
        component: () => import('@/views/noticeManager/notice/detail'),
        meta: { title: '通知公告详情' }
      },
      {
        path: '/noticeManager/advice',
        name: 'advice',
        component: () => import('@/views/noticeManager/advice'),
        meta: { title: '投诉建议' }
      },
      {
        path: '/noticeManager/advicePhoneSearch',
        name: 'advicePhoneSearch',
        component: () => import('@/views/noticeManager/advicePhoneSearch'),
        meta: { title: '处理电话查询' }
      }
    ]
  },
  {
    path: '/infomation',
    component: LayoutPrimary,
    children: [
      {
        path: '',
        name: 'infomation',
        component: () => import('@/views/infomation'),
        meta: { title: '须知' }
      }
    ]
  },
  {
    path: '/404',
    component: LayoutPrimary,
    children: [
      {
        path: '/',
        name: '404',
        component: () => import('@/views/error/404'),
        meta: { title: '404' }
      }
    ]
  },
  {
    path: '/admin/*'
  },
  {
    path: '/air/*'
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
