<template>
  <air-slider-captcha
    ref="verify"
    :maxFailCount="maxFailCount"
    :defaultText="defaultText"
    :successText="successText"
    :failText="failText"
    :size="size"
    :bgWidth="bgWidth"
    :blockWidth="blockWidth"
    :fetchCatpcha="getCaptcha"
    :validator="validateCaptcha"
    @change="onChange"
  >
  </air-slider-captcha>
</template>

<script>
import { fetchCaptcha, validateCaptcha } from '@/api/verify'
let state = ''

export default {
  name: 'admin-slider-captcha',
  props: {
    /**
     * 失败多少次后刷新验证码
     */
    maxFailCount: {
      type: Number,
      default: 3
    },
    /**
     * 默认提示文案
     */
    defaultText: {
      type: String,
      default: '拖动滑块,完成拼图'
    },
    /**
     * 成功提示文案
     */
    successText: {
      type: String,
      default: '验证通过'
    },
    /**
     * 失败提示文案
     */
    failText: {
      type: String,
      default: '验证失败'
    },
    /**
     * 输入框大小
     * @values default, small, large
     */
    size: {
      type: String,
      default: 'default'
    },
    bgWidth: {
      type: Number,
      default: 310
    },
    blockWidth: {
      type: Number,
      default: 50
    }
  },
  methods: {
    // 获取滑块验证码
    async getCaptcha () {
      try {
        const data = await fetchCaptcha()
        state = data.state

        return data
      } catch (err) {
        this.$message.error(err.message)
        return Promise.reject(err)
      }
    },
    // 校验滑块验证码
    async validateCaptcha (value) {
      try {
        const data = await validateCaptcha({
          percentage: (value / 310).toFixed(3) - 0,
          captchaId: state
        })

        this.$emit('change', data)

        return data
      } catch (err) {
        if (err.code === 100020010) {
          this.refresh()
        }

        return Promise.reject(err)
      }
    },
    onChange (value) {
      if (!value) {
        this.$emit('change', '')
      }
    },
    refresh () {
      this.$refs.verify.onRefresh()
    }
  }
}
</script>
