import Vue from 'vue'
import { getFlightCities } from '@/api/flight'

const app = {
  state: {
    flightCities: []
  },
  mutations: {
    SET_FLIGHT_CITY: (state, cities) => {
      if (cities) {
        state.flightCities = cities
        Vue.ls.set('FLIGHT_CITY', cities)
      } else {
        const list = Vue.ls.get('FLIGHT_CITY')

        if (list) {
          state.flightCities = list
        }
      }
    }
  },
  actions: {
    fetchFlightCities: ({ commit }) => {
      return new Promise((resolve) => {
        getFlightCities().then(cities => {
          commit('SET_FLIGHT_CITY', cities)
          resolve()
        })
      })
    }
  }
}

export default app
